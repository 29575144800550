import { FC, HTMLProps } from "react";

import { stylesHelper } from "@styles/emotion-styles/helpers/stylesHelper";

import * as styles from "./Container.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  type?: "small";
  withSidebar?: boolean;
}

const Container: FC<Props> = ({ withSidebar, type, children, ...rest }) => {
  const conditionalStyles = () => {
    return stylesHelper(styles.containerDiv, type && styles.types[type], [
      [styles.withSidebar, withSidebar],
    ]);
  };

  return (
    <div css={conditionalStyles()} {...rest}>
      {children}
    </div>
  );
};

export { Container };
