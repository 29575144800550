import { FC } from "react";

import { Separator, Typography, Button } from "@aviary";

import * as styles from "./AuthPageFooter.styles";

const AuthPageFooter: FC = () => {
  return (
    <footer css={styles.footer}>
      <Separator />
      <div css={styles.footerContainer}>
        <Typography css={styles.text}>
          Having trouble? Ask for help by contacting{" "}
          <a css={styles.link} href="mailto: api-support@fullscript.com">
            api-support@fullscript.com
          </a>
        </Typography>
        <div css={styles.button}>
          <Button 
            href="https://docs.google.com/forms/d/e/1FAIpQLSdReDkGQQQ35HmYU_67XTlPGn1sqpF2w60uiJMnfNB_83Bkjg/viewform"
            target="_blank"
            isSize="small"
          >
            Contact us for help
          </Button>
        </div>
      </div>
    </footer>
  );
};

export { AuthPageFooter };
