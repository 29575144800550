import { css } from "@emotion/react";

import { colors } from "@styles";

export const separator = css`
  height: 2px;
  margin: 0;
  background-color: ${colors.separator};
  width: 100%;
`;
