import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const containerDiv = css`
  flex-grow: 1;
  margin: 0 ${dimensions.containerMargin};
  position: relative;
  width: auto;

  max-width: ${dimensions.maxContainer};

  @media only screen and (max-width: ${dimensions.tabletMax}) {
    margin: 0 ${dimensions.containerMarginMobile};
  }

  @media only screen and (min-width: ${dimensions.breakpoints.fullHD.min + 64}px) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const withSidebar = css`
  @media only screen and (min-width: ${dimensions.fullHD}) {
    margin: 0 ${dimensions.containerMargin};
    width: auto;
  }
  @media only screen and (min-width: 1620px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const types = {
  small: css`
    max-width: 64rem;
  `,
};
