import { css } from "@emotion/react";

import { colors } from "@styles";

export const normalize = css`
  body,
  html {
    background-color: ${colors.white};
  }
`;
