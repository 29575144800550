import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const footer = css`
  width: 100%;
  justify-content: flex-end;
  margin-top: 3rem;
`;

export const text = css`
  padding-top: 1.75rem;
  @media (max-height: ${dimensions.tablet}) {
    padding-bottom: 1.75rem;
  }
`;

export const link = css`
  color: ${colors.blue.darkFamily.normal};
`;

export const button = css`
  padding-top: 1.25rem;
`;

export const footerContainer = css`
  display: flex;
  justify-content: space-between;
`;
